.star-rating {
    display: flex;
    margin-bottom: 1vh;
    /* justify-content: center; */
}

.star.selected svg {
    fill: #FFD449;
}

.star svg {
    fill: #c4c4c4;
}

.star-rating-div {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    justify-content: space-between;
    width: 50%;
}

.star-rating-div span {
    margin-right: 10px;
}

.reviewStar {
    display: block;
    margin: 0px auto;
}

.reviewStar .star svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-bottom: 2vh;
}

.reviewStar .star {
    cursor: pointer;
}


/* display */

.star-ratings {
    display: inline-block;
}

.star-ratings span {
    margin-right: 0;
}

.filled-star {
    color: #FFD449;
}

.empty-star {
    color: #bcbcbc;
}