html {
    overflow-x: hidden;
}

.background {
    margin: 5vh;
    border-radius: 15px;
    height: 90vh;
}

.header {
    background-color: #317369;
    background-image: url(../../assets/images/bg.png);
    color: #fff;
    padding-top: 10vh;
    padding-bottom: 10vh;
    border-radius: 15px 15px 0 0;
}

.row {
    display: flex;
    line-height: 0.8;
}

.farmerImg {
    width: 15vh;
    height: 15vh;
    border-radius: 10px;
    margin-right: 20px;
}

.logoImg {
    /* width: 15vh; */
    display: block;
    margin-top: -50px;
    margin-bottom: -5px;
}

.doodle1 {
    display: none;
}

.cropDetails {
    display: flex;
    margin-top: 5vh;
}

.column_1 {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    column-gap: 3vh;
    align-content: flex-start;
}

.column_2 {
    position: relative;
    height: 97vh;
    width: 50%;
    background-color: #B56210;
    border-radius: 25px 25px 0 0;
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end; */
}

.column_2.Potato {
    background-color: #B56210;
}

.column_2.Capsicum {
    background-color: #4B5902;
}

.column_2.Bitter.Gourd{
    background-color: #406b02;
}
.column_2.Chilli {
    background-color: #76010A;
}

.column_2.Tomato {
    background-color: #B63808;
}
.column_2.Bitter.Gourd .cropName {
    line-height: 1;
    left: 50px;
    text-align: left;
    font-size: 22vh;
}
.column_2.Chilli .cropName {
    left: 50px;
}
.role {
    color: #6a6a6a;
}

.col_1_div {
    display: flex;
    flex-direction: row;
    width: 95%;
    flex-wrap: wrap;
    align-items: flex-start;
}

.col_2_div p {
    line-height: 1.3;
}

.col1_row1 {
    width: 100%;
    height: 30px;
    display: flex;
    margin-top: 2vh;
    margin-bottom: 2vh;
}

.other-details {
    text-align: left;
    line-height: 0.5;
    width: 80%;
}
.farmer-details {
    text-align: left;
    line-height: 0.5;
}
.farmer-img {
    width: 20%;
}
label {
    margin-bottom: 10px;
    text-align: left;
}

.txt1 {
    width: 21vh;
    text-align: left;
    font-weight: 600;
}

.txt2 {
    width: 24vh;
    text-align: left;
}

.farmerName {
    margin-top: 10px;
}
.col_2_div .row{
    margin: auto;
}
.col_2_div{
    width: 95%;
    display: flex;
    column-gap: 3vh;
}
.cropDetails-col, .col_2_div .rating-col{
    width: 50%;
}
@keyframes slideLeft {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.cropImg {
    width: 100%;
    position: absolute;
    right: -5px;
    bottom: 10px;
    z-index: 9999;
    animation: slideLeft 1.5s ease forwards;
}

.cropName {
    position: absolute;
    font-size: 25vh;
    font-weight: 800;
    top: 4vh;
    margin: 0;
    color: rgba(255, 255, 255, 0.07);
    animation: slideRight 1.5s ease forwards;
    /* animation: runCarrot 25s linear infinite; 
    white-space: nowrap; /* Prevent the text from wrapping */
    /* overflow: hidden;
    z-index: 999; */
}

@keyframes runCarrot {
    0% {
        transform: translateX(100%);
    }

    /* Start off screen to the right */
    100% {
        transform: translateX(-100%);
    }

    /* Move to the left */
}

@keyframes slideRight {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.cropImg:hover {
    transform: translateX(-55px);
}

.doodle3 {
    width: 100%;
    position: absolute;
    right: 0;
    bottom: 0;
    opacity: 0.5;
}

/* .addReview {
    display: flex;
    flex-direction: column;
    text-align: left;
    box-shadow: #2a2a2a29 0px 0px 5px 4px;
    border-radius: 10px;
    padding: 20px 30px 40px 30px;
    width: 85%;
    margin-top: -20px;
    margin-bottom: 30px;
    height: max-content;
} */
.addReview {
    display: flex;
    flex-direction: column;
    text-align: left;
    /* box-shadow: hsl(0deg 0% 16.47% / 7%) 0px 0px 5px 4px; */
    border-radius: 5px;
    padding: 0px 5px 5px 0;
    width: 98%;
    margin-top: 10px !important;
    margin-bottom: 0px;
    height: max-content;
    margin-left: 1vh !important;
}
.addReview h1 {
    font-size: 3.2vh;
}
.review-form {
    display: flex;
    flex-direction: column;
}
.error-label{
    color: red;
    font-weight: 600;
}
button {
    background-color: #3d3d3d;
    color: #ffffff;
    border-radius: 5px;
    padding: 15px 0;
    border: none;
}

textarea {
    margin-bottom: 10px;
    border: 1px solid #c2c2c2;
    border-radius: 5px;
    padding: 10px;
}

.tab-logo {
    display: none;
}

.star-rating-div {
    line-height: 1.5;
}

.no-prod-txt1 {
    font-weight: 600;
    font-size: 2rem;
}

.no-prod-txt2 {
    font-size: 1.2rem;
    margin-top: -10px;
}

.no-prod-img {
    margin-top: 10%;
}


@media screen and (max-width: 1960px) {
.addReview h1 {
    font-size: 2.65vh;
    margin-top: 10px;
    margin-bottom: 3vh;
}
.column_2{
    height: 95vh;
}
}
@media screen and (max-width: 1520px) {

}
@media screen and (max-width: 1367px) {

    .star-rating-div {
        line-height: 1.5;
    }

    .star-ratings {
        width: auto;
    }

    .star-rating-div span {
        margin-right: 5px;
        width: 45%;
    }
}

@media screen and (max-width: 1024px) {
    .default-logo {
        display: none;
    }

    .cropDetails {
        display: flex;
        margin-top: 3vh;
        /* flex-direction: column; */
        flex-direction: column-reverse;
        padding: 0 2.8vh;
    }

    .tab-logo {
        background-color: rgb(246, 246, 246);
        height: 6vh;
        display: block;
        margin-bottom: -10px;
        margin-top: 0px;
    }

    /* .logoImg {
        width: 11vh;
    } */
    .logoImg {
        width: 9vh;
        top: 19px;
        position: absolute;
        padding-top: 20px;
        padding-left: 20px;
    }
    .logoImg.usaid {
        width: 17vh;
        top: 26px;
        position: absolute;
        padding-top: 20px;
        padding-left: 0px;
        left: 100px;
    }
    .column_1 {
        width: 100%;
        display: flex;
        /* flex-direction: column; */
        flex-wrap: wrap;
        align-content: center;
    }

    .addReview h1 {
        font-size: 2.0vh;
        text-align: center;
        margin-bottom: 20px;
    }

    .addReview {
        width: 100%;
        margin-top: 30px;
    }

    .farmerName {
        margin-bottom: 1rem;
    }

    .col_1_div {
        width: 100%;
    }

    .txt1 {
        width: 22vh;
    }

    .background {
        height: auto;
        margin: 0;
        border-radius: 0;
        padding-bottom: 4vh;
    }



    html {
        background-color: #ffffff;
    }

    .header {
        border-radius: 0;
        margin-top: 0vh;
    }

    .farmerImg {
        width: 10vh;
        height: 10vh;
    }

    .column_2 {
        width: 100%;
        height: 30vh;
        margin-bottom: 60px;
        border-radius: 25px;
    }

    .cropImg {
        width: 78%;
        bottom: -20px;
    }

    .cropName {
        font-size: 10vh;
        left: 4vh;
        top: -1vh;
    }

    .doodle3 {
        width: 70%;
    }

    .col_2_div {
        width: 100%;
    }

    .txt1, .txt2 {
        width: 50%;
    }

    .farmer-details {
        margin-left: 20px;
    }
}

@media screen and (max-width: 600px) {
    .farmer-details {
        margin-left: 0px;
    }

    .col_2_div {
        width: 100%;
        display: flex;
        column-gap: 3vh;
        flex-direction: column;
    }
    .cropDetails-col, .col_2_div .rating-col {
        width: 100%;
    }
    .farmerName {
        margin-bottom: 0.5rem;
        line-height: 1.2;
    }

    .farmerImg {
        width: 15vh;
        height: 15vh;
    }

    /* .logoImg {
        width: 12vh;
        display: block;
        margin-top: -80px;
        margin-bottom: -45px;
    } */
    .tab-logo {
        background-color: rgb(246, 246, 246);
        height: 7vh;
        display: block;
        margin-bottom: -110px;
        margin-top: 0px;
    }
    .logoImg {
        width: 12vh;
        top: 20px;
        position: absolute;
        padding-top: 20px;
        padding-left: 20px;
    }
    .logoImg.usaid {
        width: 17vh;
        top: 32px;
        position: absolute;
        padding-top: 20px;
        padding-left: 0px;
        left: 90px;
    }
    /* .logoImg {
        top: 65px;
        position: absolute;
    } */
    .farmerImg {
        width: 12vh;
        height: 12vh;
    }

    .col_1_div {
        display: flex;
        flex-direction: column;
        width: 100%;
        flex-wrap: nowrap;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .other-details {
        width: 100%;
    }
    .bio-feedback {
        margin-top: 3vh;
    }
    .addReview {
        padding: 10px 10px 30px 0px;
        background-color: #fff;
        z-index: 99;
        width: 100%;
    }

    .addReview h1 {
        font-size: 2.6vh;
        text-align: center;
    }

    .column_1 {
        /* padding: 0 4vh; */
        margin-top: 40vh;
        /* position: absolute; */
    }

    .column_2 {
        top: 80px;
        position: absolute;
        height: 28vh;
        width: 95%;
        background-color: #B56210;
        border-radius: 25px 0 0 25px;
        /* left: 31px; */
    }

    .cropImg {
        width: 96%;
        position: absolute;
        right: 10px;
        bottom: -30px;
    }

    .cropDetails {
        display: flex;
        margin-top: 7vh;
        flex-direction: column-reverse;
        /* padding:0; */
    }

    .cropName {
        position: absolute;
        font-size: 8vh;
        top: 0;
        left: 30px;
    }

    .star-ratings {
        width: auto;
    }

    .star-rating-div {
        width: 40vh !important;
        justify-content: flex-start !important;
        line-height: 1.5;
    }

    .doodle3 {
        display: none;
    }

    .doodle1 {
        position: absolute;
        display: none;
        bottom: -38vh;
        right: 0;
        z-index: 9;
    }

    .cropImg {
        animation: none;
    }

    .cropName {
        animation: none;
    }

    .no-prod-txt1 {
        font-weight: 600;
        font-size: 1.6rem;
    }

    .no-prod-img {
        margin-top: 40%;
        width: 50%;
    }
    .farmer-img {
        width: 100%;
        display: flex;
    }
}

@media screen and (max-width: 430px) {
    .star-ratings {
        width: auto;
    }

    .star-rating-div {
        width: 40vh !important;
        justify-content: flex-start !important;
        line-height: 1.5;
    }
    
}
@media screen and (max-width: 412px) {
    .star-ratings {
        width: auto;
    }

    .star-rating-div {
        width: 40vh !important;
        justify-content: flex-start !important;
        line-height: 1.5;
    }
    
    .column_1 {
        margin-top: 41vh;
    }

    .cropImg {
        right: 0;
    }
}
@media screen and (max-width: 391px) {
    .column_1 {
        margin-top: 39vh;
    }
}
@media screen and (max-width: 376px) {
    .column_1 {
        margin-top: 42vh;
    }
}
@media screen and (max-width: 360px) {
    .column_1 {
        margin-top: 48vh;
    }
}
.bio{
    line-height: 1.3;
    /* font-weight: 500; */
    margin-bottom: 1.5vh;
    box-shadow: #2a2a2a12 0px 0px 5px 4px;
    padding: 10px 20px 10px;
}
.lassana-feedback{
    line-height: 1.3;
    box-shadow: #2a2a2a12 0px 0px 5px 4px;
    padding: 10px 20px 10px;
}
.bio-feedback{

}
.outer-div-star-ratings{
    margin-top: 10px;
    /* display: flex;
    flex-direction: row-reverse;
    width: 70vh;
    column-gap: 7vh;
    margin-bottom: 3vh; */
}
.heading-bar{
    background-color: #3d3d3d;
    color: #ffffff;
    margin-top: 3vh !important;
    margin-bottom: 3vh !important;
    border-radius: 5px;
}
.heading-bar .txt1 {
    line-height: 1.3;
    margin-bottom: 4px;
    padding: 10px 20px;
}

